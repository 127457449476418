<!-- 文件下载-->
<template>
  <div class="file-download">
    <el-table :loading="zeroLoading" :data="list" border fit highlight-current-row style="width: 100%;" size="mini">
      <!-- 操作 -->
      <el-table-column :label="$t('sysOperationLog.moduleName')" align="center">
        <template v-slot="{row}">
          <el-button-group>
            <!-- 预览 -->
            <!--            <el-button :loading="btnLoading" :title="$t('button.preview')" size="mini" icon="el-icon-view" plain type="primary" @click="viewBtn(row)"/>-->
            <!-- 下载 -->
            <el-button v-if="row.fileNo" :loading="btnLoading" :title="$t('mimember.uploadfile.download')" size="mini" icon="el-icon-download" plain  type="warning" @click="downloadBtn(row)"/>
          </el-button-group>
        </template>
      </el-table-column>
      <!-- 标题 -->
      <el-table-column :label="$t('icInformation.title')" min-width="100" prop="fileName" align="center" />
      <!-- 分类 -->
      <el-table-column :label="$t('Classification')" width="70" prop="remark" align="center" />
      <el-table-column :label="$t('链接跳转')" width="100" align="center" >
        <template v-slot="{row}">
          <span v-if="row.agnetValid"><a :href="row.linkUrl" target="_blank">{{row.agnetValid}}</a></span>
          <span v-if="row.memberValid"><a :href="row.linkUrl" target="_blank"><img style="width: 100px;height: 100px" :src="row.memberValid"></a></span>
        </template>
      </el-table-column>
      <!-- 上传时间 -->
      <el-table-column :label="$t('mi.lable.updoad.time')" prop="createTime" align="center" />
    </el-table>
    <el-dialog
        :title="viewInfo.title"
        :close-on-click-modal="false"
        :fullscreen="true"
        :visible.sync="dialogVisible"
        @close="closeHandler"
    >
      <div>
        <vue-office-docx v-if="viewInfo.fileDocx" :src="viewInfo.fileDocx" />
        <vue-office-excel v-else-if="viewInfo.fileExcel" :src="viewInfo.fileExcel" style="height: 700px" />
        <vue-office-pdf v-else-if="viewInfo.filePdf" :src="viewInfo.filePdf" />
        <el-image v-else-if="viewInfo.fileIimage" :src="viewInfo.fileIimage" fit="contain"/>
        <audio v-else-if="viewInfo.fileMp3" controls controlsList="nodownload" style="width: 100%">
          <track kind="captions" />
          <source :src="viewInfo.fileMp3" type="audio/mpeg" />
        </audio>
        <video v-else-if="viewInfo.fileMp4" controls muted controlsList="nodownload" style="width: 70%">
          <track kind="captions" />
          <source :src="viewInfo.fileMp4" type="video/mp4" />
        </video>
        <div v-else-if="viewInfo.fileTxt">
          <textarea  v-model="viewInfo.fileTxt" style="width: 70%; min-height: 350px" readonly></textarea>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { pageFile, downloadFileUrl } from '@/api/ic/icFile'
  import VueOfficeDocx from '@vue-office/docx'
  import '@vue-office/docx/lib/index.css'
  import VueOfficeExcel from '@vue-office/excel'
  import '@vue-office/excel/lib/index.css'
  import VueOfficePdf from '@vue-office/pdf'
  import axios from 'axios'

  export default {
    name: "FileDownload",
    components:{
      VueOfficeDocx,
      VueOfficeExcel,
      VueOfficePdf
    },
    data() {
      return {
        viewInfo: {
          title: '',
          fileDocx: '',
          fileExcel: '',
          filePdf: '',
          fileIimage: '',
          fileMp3: '',
          fileMp4: '',
          fileTxt: ''
        },
        dialogVisible: false,
        btnLoading: false,
        zeroLoading: false,
        list: []
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.zeroLoading = true
        pageFile().then(res => {
          this.list = res.data
          this.zeroLoading = false
        }).catch(() => {
          this.zeroLoading = false
        })
      },
      // 预览
      viewBtn(row) {
        downloadFileUrl({'id': row.fileId}).then(res => {
          if (res.data.indexOf('.docx') !== -1 || res.data.indexOf('.doc') !== -1) {
            this.viewInfo.fileDocx = res.data
          } else if (res.data.indexOf('.xlsx') !== -1 || res.data.indexOf('.xls') !== -1) {
            this.viewInfo.fileExcel = res.data
          } else if (res.data.indexOf('.pdf') !== -1) {
            this.viewInfo.filePdf = res.data
          } else if (res.data.indexOf('.jpg') !== -1 || res.data.indexOf('.png') !== -1 || res.data.indexOf('.jfif') !== -1 || res.data.indexOf('.gif') !== -1) {
            this.viewInfo.fileIimage = res.data
          } else if (res.data.indexOf('.mp3') !== -1) {
            this.viewInfo.fileMp3 = res.data
          } else if (res.data.indexOf('.mp4') !== -1) {
            this.viewInfo.fileMp4 = res.data
          } else if (res.data.indexOf('.txt') !== -1) {
            axios.get(res.data).then(response => {
                  this.viewInfo.fileTxt = response.data
                }).catch(error => {
                  console.error('Fetching txt failed:', error);
                });
          } else {
            this.$message.error(this.$t('No.file_type.preview') + '!!!') // 不支持此文件类型预览
            return false
          }
          this.dialogVisible = true
          this.viewInfo.title = row.fileName
        })
      },
      // 下载
      downloadBtn(row) {
        this.btnLoading = true
        this.$confirm(this.$t('Please_Download_File'), this.$t('common.tips'), {
          confirmButtonText: this.$t('yesno.yes'),
          cancelButtonText: this.$t('yesno.no'),
          customClass: 'msgBox',
          type: 'warning'
        }).then(() => {
          downloadFileUrl({ 'id': row.fileId }).then(res => {
            const ADownload = document.createElement('a')
            ADownload.href = res.data
            ADownload.download = row.fileName
            ADownload.click()
            this.btnLoading = false
          }).catch(() => {
            // 操作失败
            this.$message.error(this.$t('bdSendRegister.operaterFail'))
            this.btnLoading = false
          })
        }).catch(() => {
          this.btnLoading = false
          this.$message.info((this.$t('pr.returned')))
        })
      },
      closeHandler() {
        this.viewInfo.fileDocx = ''
        this.viewInfo.fileExcel = ''
        this.viewInfo.filePdf = ''
        this.viewInfo.fileIimage = ''
        this.viewInfo.fileMp3 = ''
        this.viewInfo.fileMp4 = ''
        this.viewInfo.fileTxt = ''
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
