<!-- 文件中文名: 个人中心首页 -->
<template>
  <div class="personal-center">
    <div>
      <el-table
          v-loading="tableLoading"
          :data="Journalismlist"
          border
          height="200"
          fit
          @row-click="viewTableBtn"
          size="mini"
          stripe
          style="width: 100%"
          :cell-style="cellStyle"
      >
        <!-- 标题 -->
        <el-table-column :label="$t('icInformation.title')" align="center" prop="title">
          <template v-slot="{row}">
            <span v-if="row.newType === 'new'" style="color: red;margin-right: 5px;font-size: 16px">New!</span><span>{{row.title}}</span>
          </template>
        </el-table-column>
        <!-- 发布时间 -->
        <el-table-column width="200" :label="$t('busi.message.issuetime')" align="center" prop="checkTime"/>

      </el-table>
    </div>
    <!--    我的账户-->
    <el-alert v-if="monthlyCompare" type="warning" :closable="false" >
      <span :class="{'hand-cursor' :mailUpdateNum < 3 }" @click="jumpEditpage">
        {{ $t('Please_modify_email') }}
      </span>
    </el-alert>
    <el-descriptions :column="column" border class="margin-top">
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          <!-- 用户编号 -->
          {{ $t('Common.userCode') }}
        </template>
        {{ userCode }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          <!-- 用户名 -->
          {{ $t('ps.find.login.code') }}
        </template>
        <span class="user_name">{{ infousername }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          <!-- 经销商级别 -->
          {{ $t('miMember.cardType') }}
        </template>
        {{ $lt(initDict.levelType, listQuery.levelType) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          <!-- 荣衔 -->
          {{ $t('mi.honourLevel') }}
        </template>
        {{ $lt(initDict.cardType, listQuery.cardType) }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          <!-- 代办处 -->
          {{ $t('common.agent') }}
        </template>
        {{ listQuery.agentNo }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          <!-- 代办处地址 -->
          {{ $t('common.agent.sysUser.address') }}
        </template>
        {{ listQuery.agentAddress }}
      </el-descriptions-item>
      <!--登陆数-->
      <!-- <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          {{ $t('Number.of.logins') }}
        </template>
        {{ listQuery.points }}
      </el-descriptions-item> -->
      <!--      上次登录时间-->
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-tickets"></i>
          <span class="user_name">{{ $t('Last.Login.Time') }}</span>
        </template>
        <span class="user_name">{{ listQuery.lastLoginTime }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-row :gutter="10">
      <el-col :span="16">
        <div class="left_wallet">
          <!--          本位币-->
          <p>{{ B$ }} &nbsp;
            <span >MXN</span>
            <span v-if="this.companyCode !== 'KGZ'"> {{ this.symbol }}</span>
            <img v-if="this.companyCode === 'KGZ'" style="width: 12px; height: 17px"
                 :src="require('@/assets/images/kgz.jpg')"></p>
          <p v-if="language==='es_ES'">Monedero de compras {{ this.wallet }}</p>
          <p v-else>{{ this.$t('Base.currency.wallet') }} {{ this.wallet }}</p>
<!--          <p>{{ F$ }} W$</p>-->
          <!-- 电子钱包 -->
<!--          <p>{{ $t('elektronik.wallet.russia') }}</p>-->
        </div>
      </el-col>
      <el-col :span="8">
        <div class="right_wallet">
          <p>{{ H0 }} &nbsp;<span>PC</span></p>
          <!-- 积分 -->
          <p v-if="language==='es_ES'">Monedero de cupón</p>
          <p v-else>{{ $t('bvPointChange.updatePoint.1') }}</p>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="8">
        <div class="wallet">
          <!--          奖金钱包-->
          <p>{{ FB }}&nbsp;W$</p>
          <p v-if="language==='es_ES'">Monedero de bonos</p>
          <p v-else>{{ this.$t('bonus.wallet') }}</p>
        </div>
      </el-col>
<!--      <el-col :span="8">-->
<!--        <div class="wallet">-->
<!--          &lt;!&ndash;          本位币&ndash;&gt;-->
<!--          <p>{{ B$ }} &nbsp;-->
<!--            <span v-if="this.companyCode !== 'KGZ'"> {{ this.symbol }}</span>-->
<!--            <img v-if="this.companyCode === 'KGZ'" style="width: 12px; height: 17px"-->
<!--                 :src="require('@/assets/images/kgz.jpg')"></p>-->
<!--          <p v-if="language==='es_ES'">Monedero de compras {{ this.wallet }}</p>-->
<!--          <p v-else>{{ this.$t('Base.currency.wallet') }} {{ this.wallet }}</p>-->
<!--        </div>-->
<!--      </el-col>-->
      <el-col :span="8">
        <!--        旅游基金-->
        <div class="wallet">
          <p>{{ B1 }}&nbsp;W$</p>
          <p>{{ $t('travel.fund') }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <!--        名车基金-->
        <div class="wallet">
          <p>{{ B2 }}&nbsp;W$</p>
          <p>{{ $t('ac_type.B2') }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <!--        当日奖金-->
        <div class="wallet">
          <p>{{ this.dayBonusall }}&nbsp;W$</p>
          <p> {{ $t('day.bonus') }}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <!--        昨日奖金-->
        <div class="wallet">
          <p>{{ this.perdayBonusall }}&nbsp;W$</p>
          <p>{{ $t('yesterday.bonus') }}</p>
        </div>
      </el-col>
      <el-col :span="8" v-if="this.WV && this.WV !== 0">
        <!--        换货账户-->
        <div class="wallet">
          <p>{{ this.WV }}</p>
          <p>{{ $t('Exchange_goods.account') }}</p>
        </div>
      </el-col>
      <el-col :span="8" v-if="this.OB && this.OB !== 0">
        <!--        老基金账户-->
        <div class="wallet">
          <p>{{ this.OB }}</p>
          <p>{{ $t('Old_fund.account') }}</p>
        </div>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="20" style="margin-top: 20px">-->
    <!--      <el-col :span="24">-->
    <!--        <div>-->
    <!--          <el-carousel height="40px" direction="vertical" :autoplay="true" :interval="2000" indicator-position="none">-->
    <!--            <el-carousel-item v-for="item in 3" :key="item" style="margin: 0 auto">-->
    <!--              <div class="medium" style="line-height: 40px;text-align: left" @click="goNewsDetail(item)">-->
    <!--                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公告:{{ item }}</div>-->
    <!--            </el-carousel-item>-->
    <!--          </el-carousel>-->
    <!--        </div>-->
    <!--      </el-col>-->
    <!--    </el-row>-->

    <!--    <el-dialog-->
    <!--        :visible.sync="dcNoBuledialong"-->
    <!--        width="80%"-->
    <!--    >-->
    <!--      <div>-->
    <!--        <el-row :gutter="12">-->
    <!--          <el-col v-for="item in NewsList" :key="item.announceId" :span="8">-->
    <!--            <div @click="goNewsDetail(item)">-->
    <!--              <el-card shadow="hover">-->
    <!--                <p style="font-size: 18px">{{ $t(initDict.icAnnounceList) }}:{{ item.title }}</p>-->
    <!--                <p>{{ $t(initDict.issuetime) }}:{{ item.checkTime }}</p>-->
    <!--              </el-card>-->
    <!--            </div>-->
    <!--          </el-col>-->
    <!--        </el-row>-->
    <!--      </div>-->
    <!--      &lt;!&ndash; 尾部按钮 &ndash;&gt;-->
    <!--      <span slot="footer" class="dialog-footer">-->
    <!--    <el-button type="primary" @click="dcNoBuledialong = false">{{ $t('operation.button.cancel') }}</el-button>-->
    <!--  </span>-->
    <!--    </el-dialog>-->
    <HonorProgressBar></HonorProgressBar>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {
  bdSumByTime,
  getPersonalData1,
  getPersonalData2,
  icAnnouncesList1, icAnnouncesList2,
  Signindex,
  userMemberInfo
} from "@/api/rus/api";
import {listByDTO} from "@/api/po/procQualification";
import HonorProgressBar from "@/components/Pagination/HonorProgressBar.vue";

export default {
  name: 'PersonalCenter',
  directives: {},
  components: {HonorProgressBar},
  data() {
    return {
      monthlyCompare: '',
      mailUpdateNum: '',
      // 新闻
      Journalismlist:[],
      tableLoading:false,
      topAlertNum: 0,
      topAlertList: [],
      topAlertShow: true,
      topAlertInfoShow: false,
      listloading: false,
      infousername: '',
      symbol: null,
      wallet: null,
      electronics: null,
      doLoading: false,
      F$: 0,
      FB: 0,
      B$: 0,
      H0: 0,
      perdayBonusall: 0,
      dayBonusall: 0,
      B1: 0,
      B2: 0,
      WV: 0,
      OB: 0,
      dcNoBuledialong: true,
      NewsList: [],
      listQuery: {
        agentNo: '',
        agentAddress: '',
        levelType: '',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < 1657728001000;
        }
      },
      listQuery1: {
        starTime: '',
        endTime: '',
        userCode: ''
      },
      totalbonusL: 0,
      column: 2,
      initDict: {
        issuetime: 'busi.message.issuetime',
        icAnnounceList: 'icAnnounceList.list',
        levelType: 'ru.member.level',
        cardType: 'ru.mimember.cardtype'
      }
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'ckCode',
      'companyCode',
    ]),
  },
  async created() {
    this.getList()
    this.getWidth()
    this.getSymbol()
    // this.$store.dispatch('user/setCkCode', this.companyCode)
    getPersonalData1()
        .then(res => {
          this.dayBonusall = res.data.dayBonusall
          this.perdayBonusall = res.data.perdayBonusall
          // console.log(res.data, '个人中心的getPersonalData1')
        })
        .catch(err => {
          console.error(err)
        })
    getPersonalData2()
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].acType === 'F$') {
              this.F$ = res.data[i].validBalance
            }
            if (res.data[i].acType === 'FB') {
              this.FB = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B$') {
              this.B$ = res.data[i].validBalance
            }
            if (res.data[i].acType === 'H0') {
              this.H0 = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B1') {
              this.B1 = res.data[i].validBalance
            }
            if (res.data[i].acType === 'B2') {
              this.B2 = res.data[i].validBalance
            }
            if (res.data[i].acType === 'WV') {
              this.WV = res.data[i].validBalance
            }
            if (res.data[i].acType === 'OB') {
              this.OB = res.data[i].validBalance
            }
          }
        })
        .catch(err => {
          console.error(err)
        })

    await Signindex({memberNo: this.userCode}).then(res => {
      this.listQuery = res.data
      // this.listQuery.cardType = (Number(res.data.cardType) + 1).toString()
    }).catch(err => {
      console.error(err)
    })

    icAnnouncesList1({page: 1, limit: 10}).then(res => {
      this.NewsList = res.data.records
    }).catch(err => {
      console.warn(err)
    })
    await userMemberInfo({memberNo: this.userCode})
        .then(res => {
          console.log(res.data.monthlyCompare, res.data.mailUpdateNum)
          this.monthlyCompare = res.data.monthlyCompare
          this.mailUpdateNum = res.data.mailUpdateNum === null ? 0 :  res.data.mailUpdateNum
          this.infousername = res.data.name
          this.listQuery.agentNo = res.data.agentNo
          this.listQuery.agentAddress = res.data.agentAddress
        })
        .catch(err => {
          console.error(err)
        })
    listByDTO({
      qualificationStatus: 1, //1为未使用
      orderStatus: 1 //1为正常
    })
        .then(res => {
          this.topAlertNum = res.data.length
          this.topAlertList = res.data
        })
        .catch(err => {
          console.error(err)
        })
  },
  methods: {
    cellStyle(data){
      if(data){
        return 'cursor:pointer;'
      }
    },
    jumpEditpage() {
      if (this.mailUpdateNum < 3) {
        this.$router.push('/profile-settings/edit-information')
      } else {
        return false
      }

    },
    // 新闻列表
    getList() {
      this.tableLoading = true
      this.btnLoading = true
      icAnnouncesList2({...this.listQuery}).then(res => {
        this.Journalismlist = res.data
        console.log(this.Journalismlist,' this.Journalismlist')
        this.total = +res.data.total || 0
        this.btnLoading = false
        this.tableLoading = false
      }).catch(err => {
        console.warn(err)
        this.btnLoading = false
        this.tableLoading = false
      })
    },
    // 新闻详情
    viewTableBtn(row){
      this.$router.push({name: 'NewsDetails', query: {item: JSON.stringify(row)}})
      // this.$router.push({path: '/news-messages/news-details', query: {item: JSON.stringify(row)}})
    },
    copyData(val) {
      // navigator.clipboard.writeText(val).then(()=>{
      //   this.$message.success('copy success')
      // })
      const textArea = document.createElement('textArea')
      textArea.value = val
      textArea.style.width = 0
      textArea.style.position = 'fixed'
      textArea.style.left = '-999px'
      textArea.style.top = '10px'
      textArea.setAttribute('readonly', 'readonly')
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      this.$message.success('copy success')
      document.body.removeChild(textArea)
    },
    toBlack5Sale() {
      this.$router.push({path: '/black-5-sale/index'})
    },
    getTotalbonus() {
      this.listloading = true
      this.listQuery1.userCode = this.userCode
      bdSumByTime({...this.listQuery1}).then(res => {
        if (res.data) {
          this.totalbonusL = res.data
        } else {
          this.totalbonusL = 0
        }
        this.listloading = false
      }).catch(err => {
        console.error(err)
      })
    },
    getWidth() {
      if (document.body.scrollWidth < 991) {
        this.column = 1
      } else {
        this.column = 2
      }
    },
    getSymbol() {
      if (this.companyCode === 'RUS') {
        //单位(货币)
        this.symbol = 'Руб'
        this.electronics = '$'
        //符号
        this.wallet = '₽'
      } else if (this.companyCode === 'MNG') {
        this.symbol = 'MNG'
        this.wallet = '₮'
      } else if (this.companyCode === 'KAZ') {
        this.symbol = 'Тг'
        this.wallet = '₸'
      } else if (this.companyCode === 'KGZ') {
        this.symbol = 'Сом'
        this.wallet = 'лв'
      } else if (this.companyCode === 'TJK') {
        this.symbol = 'somoni'
        this.wallet = 'SM'
      }else if(this.companyCode === 'LTV'){
        this.symbol = '€'
      }
    },
    goNewsDetail(item) {
      this.$router.push({path: '/news-messages/news-details', query: {item: JSON.stringify(item)}})
    },
  }
}

</script>

<style lang="scss" scoped>
.hand-cursor {
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-color: #d1b199;
  text-decoration-style: dashed;
}

.copyBtn {
  color: #0000FF;
  cursor: pointer;
}
.top-alert {
  background-color: #fef0f0;
  //color: #67c23a;
  color: #f56c6c;
  font-size: 16px;
  margin: 8px 8px 0 8px;
  min-height: 40px;
  border-radius: 13px;
  line-height: 40px;
  .top {
    display: flex;
    justify-content: space-between;

    .right {
      margin-right: 15px;
      color: #8F8F8F;
      cursor: pointer;
    }

    .left {
      margin-left: 15px;
      color: #f56c6c;
    }

    .center {
      flex: 1;
      text-align: left;
      padding-left: 10px;
      cursor: pointer;
    }
  }
  .bottom {
    text-align: left;
    color: #3F3F3F;
    p {
      margin: 0 20px;
    }
  }
}

.user_name {
  font-size: 12px;
}

.el-row {
  //margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.left_wallet {
  height: 115px;
  padding: 10px;
  opacity: 0.9;
  border-radius: 13px;
  border-style: solid;
  border-color: #FFFFFF;
  border-width: 8px;
  //background-image: linear-gradient(to right top, #7050eb, #7d4cef, #8a47f3, #9740f6, #a438f8);
  background-image: linear-gradient(to left top, #f8fdfd, #f2fefc, #eefff8, #ecfff3, #eeffeb);

  p {
    text-align: left;
    margin: 4px;
    //color: #FFFFFF;
    color: #333333;
    font-size: 33px;
  }

}

.right_wallet {
  height: 115px;
  padding: 10px;
  opacity: 0.9;
  border-radius: 13px;
  border-style: solid;
  border-color: #FFFFFF;
  border-width: 8px;
  //background-image: linear-gradient(to right top, #394860, #394a66, #394d6c, #394f72, #395178);
  background-image: linear-gradient(to left top, #f8fdfd, #f2fefc, #eefff8, #ecfff3, #eeffeb);

  p {
    text-align: left;
    margin: 4px;
    //color: #FFFFFF;
    color: #333333;
    font-size: 33px;
  }
}

.wallet {
  height: 115px;
  padding: 10px;
  opacity: 0.9;
  border-radius: 13px;
  border-style: solid;
  border-color: #FFFFFF;
  border-width: 8px;
  background-image: linear-gradient(to left top, #f8fdfd, #f2fefc, #eefff8, #ecfff3, #eeffeb);

  p {
    text-align: left;
    margin: 4px;
    color: #333333;
    font-size: 33px;
  }

}

.el-carousel__item div {
  color: #475669;
  margin: 0;
}

.el-carousel__item {
  box-sizing: border-box;
  padding: -1px;
  border-radius: 5px;
  cursor: pointer;
}

.el-carousel__item:nth-child(2n) {
  border: 1px solid #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  border: 1px solid #d3dce6;
}

@media screen and (max-width: 991px) {
  .left_wallet {
    height: 50px;

    p {
      font-size: 16px !important;
    }
  }
  .right_wallet {
    height: 50px;

    p {
      font-size: 16px !important;
    }
  }
  .wallet {
    height: 70px;

    p {
      font-size: 16px !important;
    }
  }
}
</style>
