<!-- 文件中文名: 在线支付页面 -->
<template>
  <div class="online-payment app-container">
<!--    <iframe style="width: 1000px;height: 500px" src="./index.html"></iframe>-->
    <iframe :src="iframeUrl" width="100%" height="600px" style="border: none"></iframe>
    <el-form ref="payOnlineForm" :model="PayOnlineForm" label-position="right" label-width="auto" size="small"
             style="text-align: left">
      <div style="width: 50%;margin: 0 auto">
        <!--在线支付-->
        <h3>{{ $t('online.payment') }}</h3>
        <!--订单编号-->
        <el-form-item :label="$t('order.no')">
          {{ PayOnlineForm.orderNo }}
        </el-form-item>
        <!--总价格-->
        <el-form-item :label="$t('order.total.price')">
          {{ PayOnlineForm.payAmount }} W$
        </el-form-item>
        <el-form-item :label="$t('名字:')">
          <el-input v-model="PayOnlineForm.customer.name" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('姓名:')">
          <el-input v-model="PayOnlineForm.customer.lastName" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('手机号:')">
          <el-input v-model="PayOnlineForm.customer.phoneNumber" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('邮箱:')">
          <el-input v-model="PayOnlineForm.customer.email" ></el-input>
        </el-form-item>
<!--        <el-form-item :label="$t('description:')">-->
<!--          <el-input v-model="PayOnlineForm.description" ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item :label="$t('银行卡号:')">
          <el-input v-model="PayOnlineForm.card.cardNumber" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('银行:')">
          <el-input v-model="PayOnlineForm.card.holderName" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('CVV:')">
          <el-input v-model="PayOnlineForm.card.cvv2" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('年:')">
          <el-input v-model="PayOnlineForm.card.expirationYear" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('月:')">
          <el-input v-model="PayOnlineForm.card.expirationMonth" ></el-input>
        </el-form-item>
        <el-form-item>
<!--          <el-button-->
<!--              :loading="btnLoading"-->
<!--              class="filter-item"-->
<!--              type="primary"-->
<!--              @click="submitPayment"-->
<!--          >-->
<!--            &lt;!&ndash;支付 Common.pay&ndash;&gt;-->
<!--            {{ $t('创建客户') }}-->
<!--          </el-button>-->
<!--          <el-button-->
<!--              :loading="btnLoading"-->
<!--              class="filter-item"-->
<!--              type="primary"-->
<!--              @click="submitPayment1"-->
<!--          >-->
<!--            &lt;!&ndash;支付&ndash;&gt;-->
<!--            {{ $t('新建银行卡') }}-->
<!--          </el-button>-->
<!--          <el-button-->
<!--              :loading="btnLoading"-->
<!--              class="filter-item"-->
<!--              type="primary"-->
<!--              @click="submitPayment2"-->
<!--          >-->
<!--            &lt;!&ndash;支付&ndash;&gt;-->
<!--            {{ $t('新建卡片') }}-->
<!--          </el-button>-->
<!--          <el-button-->
<!--              :loading="btnLoading"-->
<!--              class="filter-item"-->
<!--              type="primary"-->
<!--              @click="submitPayment3"-->
<!--          >-->
<!--            &lt;!&ndash;支付&ndash;&gt;-->
<!--            {{ $t('支付') }}-->
<!--          </el-button>-->
<!--          <el-button-->
<!--              :loading="btnLoading"-->
<!--              class="filter-item"-->
<!--              type="primary"-->
<!--              @click="submitPayment4"-->
<!--          >-->
<!--            &lt;!&ndash;支付&ndash;&gt;-->
<!--            {{ $t('获取银行信息') }}-->
<!--          </el-button>-->
          <el-button
              :loading="btnLoading"
              class="filter-item"
              type="primary"
              @click="submitPayment5"
          >
            <!--支付-->
            {{ $t('生成付款') }}
          </el-button>
          <el-button
              :loading="btnLoading"
              class="filter-item"
              type="primary"
              @click="submitPayment6"
          >
            <!--支付-->
            {{ $t('生成付款') }}
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
export default {
  name: 'OnlinePaument',
  data() {
    return {
      iframeUrl:'/static/html/openpay.html',
      YearList: [],
      Recharge_Order_Number:'',
      MonthList: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      orderNo: '',
      payMoney: '',
      PayOnlineForm: {
        // description:'',
        customer:{
          name:'',
          email:'',
          lastName:'',
          phoneNumber:'',
        },
        card:{
          cardNumber:'',
          holderName:'',
          expirationYear:'',
          expirationMonth:'',
          cvv2:'',
        }
      },
      Openpay:'',
      OpenPay:'',
      openpayanhou:'',
      PayOnlineData: {},
      onlineForm: {
        postUrl: null,
        data: null,
        sign: null,
        parameters: null
      },
      btnLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'companyCode',
    ]),
  },
  created() {
    // this.Openpay = require('openpay');
    // this.openpay = new Openpay
    // this.openpay.setMerchantId('mfzywluxtxm1d30ci8yh');
    // this.openpay.setPrivateKey('sk_c5ecd75fe389438ba7ebbd3093ef5f76');
    // this.openpay.setProductionReady(false)
    // this.initializeOpenPay()
    if (!sessionStorage.getItem("PayOnlineData")) {
      console.log('PayOnlineData不存在!')
      this.$router.go(-1)
    }
    this.PayOnlineData = JSON.parse(sessionStorage.getItem("PayOnlineData"))
    this.Recharge_Order_Number = JSON.parse(sessionStorage.getItem("Recharge_Order_Number"))
    console.log(this.Recharge_Order_Number)
    if(this.PayOnlineData){
      this.PayOnlineForm.payAmount = this.PayOnlineData.totalAmount
      this.PayOnlineForm.orderNo = this.PayOnlineData.orderNo
    }
  },
  methods: {
    async submitPayment() {
      var newCustomer = {
        "name":"John",
        "email":"johndoe@example.com",
        "last_name":"Doe",
        "address":{
          "city":"Queretaro",
          "state":"Queretaro",
          "line1":"Calle Morelos no 10",
          "line2":"col. san pablo",
          "postal_code":"76000",
          "country_code":"MX"
        },
        "phone_number":"44209087654"
      };

      this.openpay.customers.create(newCustomer, function(error, body, response) {
        console.log(error,body,response)
      });
    },
   async  submitPayment1(){
      var customerId = 'aknltvpcszzpdbdesia1';
      var newBankAccount = {
        "clabe": "000000000000000000",
        "holder_name": "John Doe",
        "alias": "Alias"
      };
      this.openpay.customers.bankaccounts.create(
          customerId,
          newBankAccount,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
    async  submitPayment2(){
      var customerId = 'aknltvpcszzpdbdesia1';
      var newCard = {
        "card_number": "4111111111111111",
        "holder_name": "John Doe",
        "expiration_year": 24,
        "expiration_month": "12",
        "cvv2": "111"
      };
      this.openpay.customers.cards.create(
          customerId, newCard,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
    async  submitPayment3(){
      var newCharge = {
        "source_id": null,
        "method": "store",
        "amount": 1,
        "currency": "MXN",
        "iva": "10",
        "description": "Cargo inicial a mi cuenta",
        "device_session_id": null,
        "customer": {
          "name": "Cliente Colombia",
          "last_name": "Vazquez Juarez",
          "phone_number": "4448936475",
          "email": "juan.vazquez@empresa.co"
        }
      }

      this.openpay.charges.create(
          newCharge,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
    async  submitPayment4(){
      var customerId = 'aknltvpcszzpdbdesia1';
      var bankAccountId = 'bymyspgz43qlbzparujw';
      this.openpay.customers.bankaccounts.get(
          customerId,
          bankAccountId,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
    async  submitPayment5(){
      var newCharge = {
        "source_id": null,
        "method": "store",
        "amount": this.PayOnlineForm.payAmount,
        "currency": "MXN",
        "iva": "10",
        "description": "Cargo inicial a mi cuenta",
        "device_session_id": null,
        "customer": {
          "name": "Cliente Colombia",
          "last_name": "Vazquez Juarez",
          "phone_number": "4448936475",
          "email": "juan.vazquez@empresa.co"
        },
        "card": {
          "card_number": "4444444444444448",
          "holder_name": "John Doe",
          "expiration_year": "24",
          "expiration_month": "12",
          "cvv2": "110",
        },
        "order_id" : "oid-00724"
      };
      this.openpay.charges.create(newCharge,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
    submitPayment6(){
      var newCharge = {
        "amount": 1,
        "currency": "MXN",
        "description": "Cargo inicial a mi cuenta",
        "order_id" : "ord-00001",
        "send_email":"false",
        "customer": {
          "name": "Cliente Colombia",
          "last_name": "Vazquez Juarez",
          "phone_number": "4448936475",
          "email": "juan.vazquez@empresa.co"
        },
        "redirect_url":"https://www.sitiodelcomercio.com",
      };
      this.OpenPay.checkout.create(newCharge,
          function (error, body, response) {
            console.log(error,body,response)
          });
    },
  },
}

</script>

<style scoped>

</style>
