import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/index.vue'
import OrderList from '@/router/module/order-list'
import AccountManager from '@/router/module/account-manager'
import PersonalCenter from '@/router/module/personal-center'
import PapersDownload from '@/router/module/papers-download'
import FinancialManagement from '@/router/module/financial-management'
import ProfileSettings from '@/router/module/profile-settings'
import MemberRegister from '@/router/module/member-register'
import NewsMessages from '@/router/module/news-messages'
import NewOrderCx from '@/router/module/new-order-cx'
import NewOrderSj from '@/router/module/new-order-sj'
import QuestionnaireSurvey from '@/router/module/questionnaire'
import black5sale from '@/router/module/black-5-sale'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const constantRoutes = [
    {
        path: '/pay/return-page',
        component: () => import('@/views/pay/index'),
        meta: {
            title: 'creditCard.payResult'
        },
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        meta: {
            title: '404 Page Not Found'
        },
        hidden: true
    },
    // {
    //     path: '/reSetMember',
    //     component: () => import('@/views/account-inherit/index'),
    //     name: 'AccountInherit',
    //     meta: {
    //         title: '账号继承'
    //     },
    //     hidden: true
    // },
    {
        path: '/',
        component: Layout,
        meta: {
            title: 'module_type.1'
        },
        hidden: true,
        children: [
            {
                path: '',
                component: () => import('@/views/home/index'),
                name: 'Home',
                meta: {title: 'Menu.homePage'},  //首页
                hidden: true
            },
            {
                path: '/memberRegister-noLogin',
                component: () => import('@/views/member-register-no-login/index'),
                meta: {
                    title: 'Dealer_registration_not_logged_in' // 经销商注册未登录
                },
                hidden: true
            },
            {
                path: '/member-register-no-login-res',
                component: () => import('@/views/member-register-no-login/NoLoginRegisterRes'),
                meta: {
                    title: 'Dealer_registration_not_logged_in_results' // 经销商注册未登录结果
                },
                hidden: true
            },
            {
                path: 'searchdetails',
                component: () => import('@/views/searchdetails/index'),
                name: 'SearchDetails',
                meta: {title: 'pl.plate.search'}  //搜索页
            },
            {
                path: 'blank',
                component: () => import('@/views/searchdetails/blank'),
                name: 'Blank',
                meta: {title: 'Search.Transit.Page'}  //搜索中转页
            },
            {
                path: 'login',
                component: () => import('@/views/login/index'),
                name: 'Login',
                meta: {title: 'operation.button.login'}  //登录
            },
            {
                path: 'forgotpassword',
                component: () => import('@/views/forgotpassword/index'),
                name: 'ForgotPassword',
                meta: {title: 'operation.button.login'}  //忘记密码
            },
            {
                path: 'goodslist',
                component: () => import('@/views/goods/index'),
                name: 'GoodsList',
                meta: {title: 'pmProductList.productList.title'}, //商品列表
                hidden: true
            },
            {
                path: 'allGoodsList',
                component: () => import('@/views/goods/AllGoodsList'),
                name: 'AllGoodsList',
                meta: {title: 'pmProductList.productList.title'},  //全部商品
                hidden: true
            },{
                path: 'admissionticket',
                component: () => import('@/views/goods/admissionticket'),
                name: 'AdmissionTicket',
                meta: {title: 'Purchase.ticket'},  //门票购买
                hidden: true
            },
            {
                path: 'allGoodsListCx',
                component: () => import('@/views/goods/AllGoodsListCx'),
                name: 'AllGoodsListCx',
                meta: {title: 'pmProductList.productList.title'},  //全部商品
                hidden: true
            },
            {
                path: 'goodslist2',
                component: () => import('@/views/goods/GoodsDoing'),
                name: 'GoodsDoing',
                meta: {title: 'pmProductList.productList.title'},
                hidden: true
            },
            {
                path: 'goodsdetail',
                component: () => import('@/views/goods/GoodsDetail'),
                name: 'GoodsDetail',
                meta: {title: 'mall.ProductDetail'},  //商品详情
                hidden: true
            },
            {
                path: 'aboutus',
                component: () => import('@/views/footer/Aboutus'),
                name: 'AboutUs',
                meta: {title: 'login.aboutme'},  //关于我们
                hidden: true
            },
            {
                path: 'shopping-cart',
                component: () => import('@/views/goods/shopping-cart'),
                name: 'Shopping-Cart',
                meta: {title: 'label.shopping.cart'},  // 购物车
                hidden: true
            },
            {
                path: 'order-settle',
                component: () => import('@/views/order-settle/index'),
                name: 'OrderSettle',
                meta: {title: 'GoodsListOrderSettle'},  // 商品结算
                hidden: true
            },
            {
                path: 'publicofferingagreement',
                component: () => import('@/views/footer/PublicOfferingAgreement'),
                name: 'PublicOfferingAgreement',
                meta: {title: 'public.offer.agreement'},  //公开发售协议
                hidden: true
            },
            {
                path: 'websiteorder',
                component: () => import('@/views/footer/WebsiteOrder'),
                name: 'WebsiteOrder',
                meta: {title: 'website.order'},  // 如何在网站上下订单
                hidden: true
            },
            {
                path: 'paymentmethod',
                component: () => import('@/views/footer/paymentmethod'),
                name: 'PaymentMethod',
                meta: {title: 'Payment.method'},  // 支付方式
                hidden: true
            },
            // {
            //     path: 'privacypolicy',
            //     component: () => import('@/views/footer/PrivacyPolicy'),
            //     name: 'PrivacyPolicy',
            //     meta: {title: 'Privacy.Policy'},  // 个人隐私保护政策
            //     hidden: true
            // },
            {
                path: 'returngoods',
                component: () => import('@/views/footer/ReturnGoods'),
                name: 'ReturnGoods',
                meta: {title: 'ic.guide.delivery'},  //物流配送
                hidden: true
            },
            {
                path: 'returngoods2',
                component: () => import('@/views/footer/ReturnGoods2'),
                name: 'ReturnGoods2',
                meta: {title: 'ReceiptType.ret.rules'},  //退货政策
                hidden: true
            },
            {
                path: 'sitemap',
                component: () => import('@/views/footer/SiteMap'),
                name: 'SiteMap',
                meta: {title: 'Sitemap'},  //网站地图
                hidden: true
            },
            {
                path: 'certificate',
                component: () => import('@/views/footer/Certificate'),
                name: 'Certificate',
                meta: {title: 'certificate'},  //证明书
                hidden: true
            },
            {
                path: 'securitycode',
                component: () => import('@/views/footer/securitycode'),
                name: 'SecurityCode',
                meta: {title: 'security_code'},  // 防伪码验证
                hidden: true
            },
            {
                path: 'zerocertificate',
                component: () => import('@/views/footer/zerocertificate'),
                name: 'ZeroCertificate',
                meta: {title: 'zero.certificate'},  //证明书
                hidden: true
            },
            {
                path: 'manufacturer',
                component: () => import('@/views/footer/Manufacturer'),
                name: 'Manufacturer',
                meta: {title: 'manufacturer'},  //制造商
                hidden: true
            },
            {
                path: 'pay-success',
                component: () => import('@/views/pay/index'),
                name: 'PayReturnPage',
                meta: {title: 'OrderGoodsResults'},  //订货结果
                hidden: true
            },
            {
                path: 'pay-online',
                component: () => import('@/views/order-settle/pay-online'),
                name: 'PayOnline',
                meta: {title: 'online.payment'},  //在线支付
                hidden: true
            },
            {
                path: 'online-payment',
                component: () => import('@/views/order-settle/online-payment'),
                name: 'OnlinePaument',
                meta: {title: 'online.payment'},  //在线支付
                hidden: true
            },
            {
                path: 'credit-card-pay',
                component: () => import('@/views/order-settle/credit-card-pay'),
                name: 'CreditCardPay',
                meta: {title: 'Credit_Card_Payment'},  //信用卡支付
                hidden: true
            },
            {
                path: 'pay-online-result',
                component: () => import('@/views/pay/pay-online-result'),
                name: 'PayOnlineResult',
                meta: {title: 'OrderGoodsResults'},  //订货结果
                hidden: true
            },
            {
                path: '/alliance-introduce',
                component: () => import('@/views/about-us/alliance-introduce'),
                name: 'AllianceIntroduce',
                meta: {title: 'Alliance.introduce'},  //联盟介绍
                hidden: true
            },
            {
                path: '/alliance-leader',
                component: () => import('@/views/about-us/alliance-leader'),
                name: 'AllianceLeader',
                meta: {title: 'Alliance.leader'},  //联盟领导人介绍
                hidden: true
            },
            {
                path: '/alliance-architecture',
                component: () => import('@/views/about-us/alliance-architecture'),
                name: 'AllianceArchitecture',
                meta: {title: 'Alliance.architecture'},  //联盟架构
                hidden: true
            },
            {
                path: '/unit-introduce',
                component: () => import('@/views/about-us/unit-introduce'),
                name: 'UnitIntroduce',
                meta: {title: 'Alliance.unit'},  //联盟发起单位介绍
                hidden: true
            },
            {
                path: 'honorandcommendation',
                component: () => import('@/views/footer/honorandcommendation'),
                name: 'HonorAndCommendation',
                meta: {title: 'Honor.palace'},  //荣誉殿堂
                hidden: true
            },
            {
                path: 'honor-personal',
                component: () => import('@/views/footer/honor-personal'),
                name: 'HonorPersonal',
                meta: {title: 'Honor.palace'},  //荣誉殿堂(个人介绍)
            },
            {
                path: 'newsandinformation',
                component: () => import('@/views/footer/newsandinformation'),
                name: 'NewsAndInformation',
                meta: {title: 'app.news'},  //新闻资讯
                hidden: true
            }
            ,
            {
                path: 'news-details',
                component: () => import('@/views/news-messages/NewsDetails'),
                name: 'NewsDetails',
                meta: {title: 'ic.news.announce'},  // 新闻公告详情
                hidden: true
            }
            ,

            {
                path: '/contact-us',
                component: () => import('@/views/about-us/contact-us'),
                name: 'ContactUs',
                meta: {title: 'tips.contactUs'},  //联系我们
                hidden: true
            },
            {
                path: '/downloadapp',
                component: () => import('@/views/downloadapp/index'),
                name: 'DownloadApp',
                meta: {title: '下载app'},  //
                hidden: true
            }
        ]
    }
]

export const asyncRoutes = [
    //...不能省略
    ...PersonalCenter,
    ...MemberRegister,
    ...NewOrderCx,
    ...NewOrderSj,
    ...OrderList,
    ...AccountManager,
    ...FinancialManagement,
    ...ProfileSettings,
    ...QuestionnaireSurvey,
    ...NewsMessages,
    ...black5sale,
    ...PapersDownload
]

// 上传
const createRouter = () => new VueRouter({
    scrollBehavior: () => ({y: 0}),
    routes:constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
