<!-- 文件中文名: 新闻公告详情 -->
<template>
  <div class="NewsDetails app-container">
      <h3>{{ titleObj.title }}</h3>
      <p>{{ titleObj.checkTime }}</p>
      <div style="text-align: justify;" v-html="titleObj.content" />
      <!--返回-->
      <el-button @click="$router.go(-1)">{{ this.$t('operation.button.return') }}</el-button>
  </div>
</template>

<script>

import {icAnnouncesView} from "@/api/rus/api";

export default {
  name: 'NewsDetails',
  directives: {},
  components: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20
      },
      list: [{}],
      titleObj: {},
      doLoading: false,
      total: 0
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {
  },
  created() {
    this.titleObj = JSON.parse(this.$route.query.item.toString())
    const id = this.titleObj.announceId
    this.getList(id)
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    getList(id) {
      icAnnouncesView({id: id}).then(() => {
      }).catch(err => {
        console.error(err)
      })
    },
  }
}

</script>

<style scoped>

</style>
