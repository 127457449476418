<template>
  <div>
    <el-table
        v-if="((orderType == 17 || orderType == 18) && this.buy_LAN00001924_num > 0)"
        border
        :data="LAN00001924_list"
        header-align="center"
        style="width: 100%">
      <el-table-column prop="goodsCode" align="center" :label="$t('BiGoods.goodsCode')"/>
      <el-table-column prop="goodsName" align="center" :label="$t('BiGoods.name')"></el-table-column>
      <el-table-column width="100" prop="standardPv" align="center" :label="$t('PV')"></el-table-column>
      <el-table-column width="100" prop="standardPrice" align="center" :label="$t('pd.price')"></el-table-column>
      <el-table-column :label="$t('biBarCode.quantity')" align="center" width="140">
        <template slot-scope="{row}">
          <el-input-number
              v-model="row.quantity"
              size="mini"
              :min="0"
              style="width:100px;"
              @change="LAN00001924_Change(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "SetupSizeSelection",
  data(){
    return{
      tableKey:0,
      LAN00001924_num:0,
      LAN00001924_list:[
        {
          goodsCode: 'D013-01',
          goodsName: ' Plantillas con aniones 35-36',
          price: 0,
          id: -23,
          isGive: 1,
          standardPv: 0,
          standardPrice: 0,
          quantity: 0,
          proPv: 0,
          proPriceB: 0,
          proPrice: 0,
          standardPriceB: 0,
          proF000: 0
        },{
          goodsCode: 'D013-02',
          goodsName: ' Plantillas con aniones 37-38',
          price: 0,
          id: -24,
          isGive: 1,
          standardPv: 0,
          standardPrice: 0,
          quantity: 0,
          proPv: 0,
          proPriceB: 0,
          proPrice: 0,
          standardPriceB: 0,
          proF000: 0
        },{
          goodsCode: 'D013-03',
          goodsName: ' Plantillas con aniones 39-40',
          price: 0,
          id: -25,
          isGive: 1,
          standardPv: 0,
          standardPrice: 0,
          quantity: 0,
          proPv: 0,
          proPriceB: 0,
          proPrice: 0,
          standardPriceB: 0,
          proF000: 0
        },{
          goodsCode: 'D013-04',
          goodsName: ' Plantillas con aniones 41-42',
          price: 0,
          id: -26,
          isGive: 1,
          standardPv: 0,
          standardPrice: 0,
          quantity: 0,
          proPv: 0,
          proPriceB: 0,
          proPrice: 0,
          standardPriceB: 0,
          proF000: 0
        },{
          goodsCode: 'D013-05',
          goodsName: ' Plantillas con aniones 43-44',
          price: 0,
          id: -27,
          isGive: 1,
          standardPv: 0,
          standardPrice: 0,
          quantity: 0,
          proPv: 0,
          proPriceB: 0,
          proPrice: 0,
          standardPriceB: 0,
          proF000: 0
        },
      ]
    }
  },
  props: {
    orderType: {
      type: Number,
      default: 0
    },
    buy_LAN00001924_num: {
      type: Number,
      default: 0
    },
    shoppingList: {
      type: Array ,
    }
  },
  watch:{
    // shoppingList:{
    //   deep:true,
    //   handler:function (val){
    //     val.forEach(v=>{
    //       if(v.goodsCode === 'LA-N000019-24'){
    //         this.LAN00001924_num = v.quantity
    //       }
    //     })
    //     console.log(this.LAN00001924_num,'this.LAN00001924_num')
    //   },
    //   immediate:true
    // },
    buy_LAN00001924_num:{
      deep:true,
      handler:function (val){
        console.log(val,'数量')
        if(val === 0){
          this.LAN00001924_list.forEach(v=>{
            v.quantity = 0
            sessionStorage.removeItem('ChooseSizeList')
            this.LAN00001924_Change()
          })
        }
      },
      immediate:true
    },
  },
  methods:{
    LAN00001924_Change(){
      let num = 0
      this.LAN00001924_list.forEach(v=>{
        if(v.quantity > 0){
          num += v.quantity
        }
      })
      console.log(num,this.LAN00001924_num * 2,'123')
      this.$emit('LAN00001924_Change', this.LAN00001924_list)
      if(this.buy_LAN00001924_num * 2 === num){
        console.log(1111)
      }else{
        return this.$message.error(this.$t('The.quantity.of.products'))
      }
    }
  }
}
</script>

<style scoped>

</style>

