<!-- 文件中文名: 黑5促销页面-->
<template>
  <div class="Black5Sale app-container">
    <el-form :model="orderForm" ref="orderForm" size="small">
      <!--   黑5促销   -->
      <h3 style="color: #b3640d">{{ $t('Black_friday.Promotion') }}</h3>
      <el-form-item
          class="hong-xing"
          :label="$t('miMember.memberNo')+':'"
          size="mini"
          prop="memberNo"
          :rules="[{ validator: validateMemberNo, trigger: 'blur' }]"
      >
        <el-input
            v-model="orderForm.memberNo"
            style="max-width: 200px"
            :placeholder="$t('')"
            clearable
        />
      </el-form-item>
      <!--资格码-->
      <el-form-item
          class="hong-xing"
          :label="$t('Qualification_code')+':'"
          size="mini"
          prop="qualificationCode"
          :rules="[{ validator: (rule, value, callback)=>validateQualificationCode(rule, value, callback), trigger: 'change' }]"
      >
        <el-autocomplete
            class="inline-input"
            v-model="orderForm.qualificationCode"
            :fetch-suggestions="querySearch"
            :placeholder="$t('Qualification_code')"
            clearable
        ></el-autocomplete>
      </el-form-item>
      <h3 style="color: #b3640d">{{ $t('mall.ProductsList') }}</h3>
      <el-form-item
          :label="$t('mi.input.select') + ':'"
          v-if="companyCode === 'LTV'"
          style="margin-bottom: 0"
          required
      >
        <el-radio-group v-model="goodsList0Radio">
          <div v-for="ele in goodsList0" :key="ele.goodsCode">
            <el-radio :label="ele.goodsCode" border class="mb5">
              <span>{{ ele.goodsCode ===  '---' ? '' : ele.goodsCode }}</span>&nbsp;
              <span v-show="ele.goodsName === 'product.Gift_pack'">185$</span>&nbsp;<span>{{ $t(ele.goodsName) }}</span>&nbsp;<span v-show="ele.goodsName === 'product.Gift_pack'">* 1</span>
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('mi.input.select') + ':'" v-if="companyCode === 'TRA'" style="margin-bottom: 0" required>
        <el-radio-group v-model="goodsList1Radio">
          <div v-for="ele in goodsList1" :key="ele.goodsCode">
            <el-radio :label="ele.goodsCode" border class="mb5">{{ ele.goodsCode }} {{ ele.goodsName }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('mi.input.select') + ':'" v-if="companyCode === 'TRA'" style="margin-bottom: 0" required>
        <el-radio-group v-model="goodsList2Radio">
          <div v-for="ele in goodsList2" :key="ele.goodsCode">
            <el-radio :label="ele.goodsCode" border class="mb5">{{ ele.goodsCode }} {{ ele.goodsName }}</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <!--          收货方式-->
      <h3 style="color: #b3640d">{{ $t('PoOrder.recType') }}</h3>
      <el-form-item :label="$t('PoOrder.recType')" required>
        <el-radio v-model="orderForm.recType" label="1" @change="setAgentAddr">
          <!--                代办处提货-->
          {{ this.$t('ru.please.goto.agent.toGet') }}
        </el-radio>
        <br>
        <!--
        <el-radio v-model="orderForm.recType" label="2" @change="setCompanyAddr">
          &lt;!&ndash;                分公司提货&ndash;&gt;
          {{ this.$t('pick.up.from.branch.office') }}
        </el-radio>
        <br>
        -->
        <!--                发到经销商-->
        <el-radio v-model="orderForm.recType" label="3" @change="setMemberAddr">
          {{ this.$t('GoodsToMember') }}
        </el-radio>
        <br>
      </el-form-item>

      <div v-if="orderForm.recType==='1'">
        <!--          代办处提货-->
        <el-form-item :label="$t('ru.please.goto.agent.toGet')" required size="mini" style="width: 100%">
          <el-radio-group v-model="orderForm.recNo" size="mini" style="width: 100%" value-key="agentNo">
            <div
                v-for="(item,index) in AgentGetGoodsList"
                :key="item.agentNo"
                :class="{ 'under-line': index===AgentGetGoodsList.length-1 }"
                style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                @click="agentRadioChange(item)"
            >
              <el-radio :label="item.agentNo" class="AgentRadio" style="height: auto;overflow: hidden;">
                <div style="display: inline-block">
                  <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                    {{ item.companyCode }}&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;({{ item.agentNo }})&nbsp;&nbsp;&nbsp;
                    {{ item.mobile }}
                  </p>
                  <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                    {{ item.storeAddr }}</p>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="orderForm.recType==='2'">
        <!--          从分公司提货-->
        <el-form-item :label="$t('pick.up.from.branch.office')" required size="mini" style="width: 100%">
          <el-radio-group v-model="orderForm.outCompanycode" size="mini" style="width: 100%" value-key="agentNo">
            <div
                v-for="(item,index) in CompanyGetGoodsList"
                :key="item.City"
                :class="{ 'under-line': index===CompanyGetGoodsList.length-1 }"
                style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                @click="outCompanyCodeRadioChange(item)"
            >
              <el-radio :label="item.companyCode" class="AgentRadio" style="height: auto;overflow: hidden;">
                <div style="display: inline-block">
                  <p>{{ $t(item.companyCode) }}-{{ $t(item.Name) }}</p>
                  <p>{{ $t(item.Addr) }}</p>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="orderForm.recType==='3'">
        <!--          地址-->
        <el-form-item :label="$t('mi.address.id')" required size="mini">
          <el-select v-model="orderForm.countryCode" filterable @change="countryChange" popper-class="selectAddr">
            <el-option
                v-for="item in countryList"
                :key="item.id"
                :label="item.country"
                :value="item.country"/>
          </el-select>
          <el-select v-model="orderForm.cityCode" filterable @change="cityChange" popper-class="selectAddr">
            <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.province"
                :value="item.id"/>
          </el-select>
        </el-form-item>
        <!--          详细地址-->
        <el-form-item :label="$t('miAgent.storeAddr')" required size="mini">
          <el-input v-model="orderForm.storeAddr"/>
        </el-form-item>
        <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required size="mini">
          <el-input v-model="orderForm.name"/>
        </el-form-item>
        <el-form-item :label="$t('fiBankbookTempRecharge.mobile')+(':')" required size="mini">
          <el-input v-model="orderForm.mobile"/>
        </el-form-item>
        <el-form-item :label="$t('shipping.cost')+(':')" size="mini">
          {{ orderForm.sendFree }}€
        </el-form-item>
      </div>
      <!--          支付信息-->
      <h3 style="color: #b3640d">{{ $t('title.payment.info') }}</h3>
      <!--         商品总金额 -->
      <el-form-item :label="$t('poAssistMemberOrder.productAmount')">
        {{ 50 + orderForm.sendFree }}€
        <span v-show="orderForm.sendFree">(50€ + {{ orderForm.sendFree }}€)</span>
      </el-form-item>
      <!--         支付密码 -->
      <el-form-item
          :label="$t('please.input.your.pay.password')"
          :rules="{ required: true, message: this.$t('label.pleaseFillIn'), trigger: ['blur', 'change'] }"
          size="mini"
          prop="payPassword"
          style="width: 400px"
      >
        <el-input v-model.trim="orderForm.payPassword" :placeholder="$t('')" clearable show-password/>
      </el-form-item>

      <div style="text-align: center; margin-bottom: 10px;margin-top: 10px">
        <!--        立即支付-->
        <el-button
            :loading="doLoading"
            icon="el-icon-check"
            size="mini"
            type="success"
            @click="formSubmit"
        >
          {{ $t('immediately.payment') }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {miMemberInfo2} from "@/api/userInfo/userInfo";
import {commonGetMiAgents, getRecAddrsWEU} from "@/api/rus/api";
import {listByDTO, procQualificationCanUse, procQualificationSaveOrder} from "@/api/po/procQualification";
import {debounce as _debounce} from "lodash"

export default {
  name: 'Black5Sale',
  // import引入的组件需要注入到对象中才能使用",
  components: {},
  // 作为子组件传入数据
  props: {},
  data() {
    return {
      restaurants: [],
      doLoading: false,
      userMemberInfoObj: null,
      AgentInfoObj: null,
      CompanyInfoObj: null,
      countryList: [],
      cityList: [],
      AgentGetGoodsList: [],
      CompanyGetGoodsList: [],
      activeIndex: 0,
      goodsList0: [
        {
          goodsCode: 'FH06-A-E',
          goodsName: 'Energy_scarf.sky_blue'
        },
        {
          goodsCode: 'FH06-B-E',
          goodsName: 'Energy_scarf.advanced_grey'
        },
        {
          goodsCode: 'FH06-C-E',
          goodsName: 'Energy_scarf.grapefruit_red'
        },
        {
          goodsCode: '---',
          goodsName: 'product.Gift_pack'
        },
      ],
      goodsList0Radio: null,
      goodsList1: [
        {
          goodsCode: 'H013A',
          goodsName: '防蓝光眼镜(黑)',
        },
        {
          goodsCode: 'H013B',
          goodsName: '防蓝光眼镜(白)',
        },
        {
          goodsCode: 'H013C',
          goodsName: '防蓝光眼镜(儿童粉)',
        },
        {
          goodsCode: 'H013D',
          goodsName: '防蓝光眼镜(儿童黑)',
        },
      ],
      goodsList1Radio: null,
      goodsList2: [
        {
          goodsCode: 'H014A',
          goodsName: '骨正基35-36',
        },
        {
          goodsCode: 'H014B',
          goodsName: '骨正基37-38',
        },
        {
          goodsCode: 'H014C',
          goodsName: '骨正基39-40',
        },
        {
          goodsCode: 'H014D',
          goodsName: '骨正基41-42',
        },
        {
          goodsCode: 'H014E',
          goodsName: '骨正基43-44',
        },
      ],
      goodsList2Radio: null,
      orderForm: {
        payType: 0,
        memberNo: null,
        qualificationCode: null,
        recType: null,
        storeAddr: null,
        name: null,
        mobile: null,
        recNo: null,
        outCompanycode: null,
        sendFree: 0,
      },
      initDict: {
        outCompanyList: 'out.company.list',
        sendfreeList: 'sendfree.weu'
      },
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'companyCode'
    ]),
  },
  watch: {},
  // 生命周期 - 创建之前",数据模型未加载,方法未加载,html模板未加载
  beforeCreate() {

  },
  // 生命周期 - 创建完成（可以访问当前this实例）",
  // 数据模型已加载，方法已加载,html模板已加载,html模板未渲染
  async created() {
    this.countryList = await this.getAddrList('')
    if (this.companyCode === 'LTV') {
      this.CompanyGetGoodsList = [
        {Name: 'LTV_Company_Name', Addr: 'LTV_Company_Addr', companyCode: 'LTV'}
      ] // 立陶宛
    }
    if (this.companyCode === 'TRA') {
      this.CompanyGetGoodsList = [
        {Name: 'TRA_Company_Name', Addr: 'TRA_Company_Addr', companyCode: 'TRA'}
      ] // 土耳其
    }
    await commonGetMiAgents()
        .then(res => {
          this.AgentGetGoodsList = res.data
        })
        .catch(err => {
          console.error(err)
        })
    this.orderForm.memberNo = this.userCode
    await miMemberInfo2({'code': this.orderForm.memberNo})
        .then(res => {
          this.userMemberInfoObj = res.data
        })
        .catch(err => {
          console.error(err)
        })

    await this.getRestaurants()
    this.orderForm.recType = '3'
    this.setMemberAddr()
  },
  // 生命周期 - 挂载之前",html模板未渲染
  beforeMount() {

  },

  // 生命周期 - 挂载完成（可以访问DOM元素）",html模板已渲染
  mounted() {
  },

  // 生命周期 - 更新之前",数据模型已更新,html模板未更新
  beforeUpdate() {

  },
  // 生命周期 - 更新之后",数据模型已更新,html模板已更新
  updated() {

  },
  // 生命周期 - 销毁之前",
  beforeDestroy() {

  },
  // 生命周期 - 销毁完成",
  destroyed() {

  },
  // 如果页面有keep-alive缓存功能，这个函数会触发",
  activated() {

  },
  // 方法集合",
  methods: {
    getRestaurants() {
      listByDTO({
        qualificationStatus: 1, //1为未使用
        orderStatus: 1 //1为正常
      })
      .then(res => {
        this.restaurants = res.data
        this.restaurants.map(ele => {
          ele.value = ele.qualificationCode
        })
      })
      .catch(err => {
        console.error(err)
      })
    },
    formSubmit() {
      if (!this.formCheck()) {
        return false
      }
      this.$confirm(this.$t('fi.sure.handle').toString(), this.$t('common.tips').toString(), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('operation.button.cancel'),
        customClass: 'msgBox',
        type: 'warning'
      }).then(() => {
        this.doLoading = true
        this.doLoading = false
        this.orderForm.goodsList = []
        if (this.goodsList0Radio) {
          if (this.goodsList0Radio === '---') {
            this.orderForm.goodsList = [
              //F009血清夫胶囊  F003-E灵芝胶囊  F002三宝口服液 F007三清口服液 F001凤凰口服液  F004六味茶  F006玫瑰寡糖
              {goodsCode: 'F009', quantity: 1},
              {goodsCode: 'F003-E', quantity: 1},
              {goodsCode: 'F002', quantity: 1},
              {goodsCode: 'F007', quantity: 1},
              {goodsCode: 'F001', quantity: 1},
              {goodsCode: 'F004', quantity: 1},
              {goodsCode: 'F006', quantity: 1}
            ]
          } else {
            this.orderForm.goodsList.push({goodsCode: this.goodsList0Radio, quantity: 1})
          }
        }
        if (this.goodsList1Radio) {
          this.orderForm.goodsList.push({goodsCode: this.goodsList1Radio, quantity: 1})
        }
        if (this.goodsList2Radio) {
          this.orderForm.goodsList.push({goodsCode: this.goodsList2Radio, quantity: 1}
          )
        }
        console.log(this.orderForm, 'this.orderForm')
        procQualificationSaveOrder({
          ...this.orderForm
        }).then(res => {
          this.doLoading = false
          if (res.data.orderNo) {
            this.$confirm(
                this.$t('OrderGoodsSuccess').toString(),
                this.$t('busi.order.orderno').toString() + ':' + res.data.orderNo, // 订单号
                {
                  confirmButtonText: this.$t('paypal.viewOrder').toString(), // 查看订单
                  cancelButtonText: this.$t('btn.continue.buy').toString(), // 继续购买
                  type: 'success',
                  center: true
                }).then(() => {
              this.$router.push({path: '/order-list/index'})
            }).catch(() => {
              this.getRestaurants()
              this.orderForm.qualificationCode = ''
            });
          }
        }).catch(err => {
          console.warn(err)
          this.doLoading = false
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('operation.button.cancel').toString()
        })
      })
    },
    formCheck() {
      if (this.orderForm.recType === '1') {
        if (!this.orderForm.recNo) {
          this.$message.error(this.$t('Please.Selec.sa').toString())
          return false
        }
      } else if (this.orderForm.recType === '2') {
        if (!this.orderForm.outCompanycode) {
          this.$message.error(this.$t('Please.Selec.sb').toString())
          return false
        }
      }
      if (!this.orderForm.cityCode && this.orderForm.recType === '3') {
        this.$message.error((this.$t('poOrder.pleaseChoose') + this.$t('busi.address')).toString())
        return false
      }
      if (!this.orderForm.storeAddr && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('Please.Selec.da').toString())
        return false
      }

      if (!this.orderForm.name && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('info.rec.name.required').toString())
        return false
      }
      if (!this.orderForm.mobile && this.orderForm.recType !== '2') {
        this.$message.error(this.$t('Please.Selec.pn').toString())
        return false
      }
      return true
    },
    querySearch(queryString, cb) {
      const restaurants = this.restaurants;
      const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    cityChange(val) {
      this.orderForm.regionCode = val
      const selectedCountry = this.countryList.filter(ele => ele.country === this.orderForm.countryCode)[0]
      if (selectedCountry.companycode === 'TRA') {
        this.orderForm.sendFree = 2
      } else {
        this.orderForm.sendFree = Number(this.$lt(this.initDict.sendfreeList, selectedCountry.companycode)) || 60
      }
    },
    async countryChange(val) {
      this.orderForm.regionCode = ''
      this.orderForm.cityCode = ''
      this.orderForm.sendFree = 0
      this.cityList = await this.getAddrList(val)
    },
    getAddrList(countryCode) {
      return new Promise((resolve, reject) => {
        getRecAddrsWEU({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    outCompanyCodeRadioChange(val) {
      this.orderForm.outCompanycode = val.companyCode
      this.orderForm.storeAddr = this.$t(val.Addr).toString()
      this.orderForm.name = ''
      this.orderForm.mobile = this.$t(val.Tel).toString()
      this.orderForm.recNo = ''
      this.CompanyInfoObj = val
    },
    agentRadioChange(val) {
      this.orderForm.storeAddr = val.storeAddr
      this.orderForm.name = val.name
      // 选择代办处提货时 手机号改为经销商默认手机号
      this.orderForm.mobile = this.userMemberInfoObj.mobile
      this.orderForm.recNo = val.agentNo
      this.AgentInfoObj = val
    },
    setAgentAddr() {
      this.orderForm.storeAddr = this.AgentInfoObj ? this.AgentInfoObj.storeAddr : ''
      this.orderForm.name = this.AgentInfoObj ? this.AgentInfoObj.name : ''
      this.orderForm.mobile = this.AgentInfoObj ? this.AgentInfoObj.mobile : ''
      this.orderForm.recNo = this.AgentInfoObj ? this.AgentInfoObj.agentNo : ''
      this.orderForm.sendFree = 0
    },
    setCompanyAddr() {
      this.orderForm.outCompanycode = this.CompanyInfoObj ? this.CompanyInfoObj.companyCode : ''
      this.orderForm.storeAddr = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Addr).toString() : ''
      this.orderForm.name = ''
      this.orderForm.mobile = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Tel).toString() : ''
      this.orderForm.recNo = ''
    },
    setMemberAddr() {
      this.orderForm.regionCode = ''
      this.orderForm.cityCode = ''
      this.orderForm.countryCode = ''
      this.orderForm.name = this.userMemberInfoObj.name
      this.orderForm.mobile = this.userMemberInfoObj.mobile
      this.orderForm.storeAddr = this.userMemberInfoObj.storeAddr
      this.orderForm.recNo = ''
    },
    validateMemberNo(rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("sys.notNull").toString()));
      }
      miMemberInfo2({'code': value})
          .then(res => {
            this.userMemberInfoObj = res.data
            callback()
          })
          .catch(err => {
            callback(new Error('x'))
            console.error(err)
          })
    },
    validateQualificationCode: _debounce(function (rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("sys.notNull").toString()));
      }
      procQualificationCanUse({'qualificationCode': value})
          .then(() => {
            callback()
          })
          .catch(err => {
            callback(new Error('x'))
            console.error(err)
          })
    }, 1000),
  }
}

</script>

<style scoped lang="scss">
.hong-xing {
  ::v-deep.el-form-item__label:before {
    content: "*";
    color: #F56C6C;
    margin-right: 4px;
  }
}
.Black5Sale {
  text-align: left;
}

.mb5 {
  margin-bottom: 5px;
}

.AgentRadio ::v-deep .el-radio__input {
  height: 35px;
}

.under-line {
  border-bottom: 1px solid #ddd
}
</style>
