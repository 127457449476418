import request from '@/utils/request'

//判断要不要补充差额
export function checkChaJia(query) {
    return request({
        url: 'order/member/checkChaJia',
        method: 'get',
        params: query
    })
}

//WV换货
export function saveWvOrder(data) {
    return request({
        url: 'order/member/saveWvOrder',
        method: 'post',
        data
    })
}

//获取商品分类
export function selectTree(query) {
    return request({
        url: '/selectTree',
        method: 'get',
        params: query
    })
}

//取首页产品展示
export function getHomeGoods(query) {
    return request({
        url: '/getHomeGoods',
        method: 'get',
        params: query
    })
}

//取首页产品展示-分页
export function getHomeGoodsPage(query) {
    return request({
        url: '/getHomeGoodsPage',
        method: 'get',
        params: query
    })
}

//取首购所有产品展示-不分页分页
export function getSGGoodsPage(query) {
    return request({
        url: '/getSGGoodsPage',
        method: 'get',
        params: query
    })
}

//取重消所有产品展示-不分页分页
export function getCXGoodsPage(query) {
    return request({
        url: '/getCXGoodsPage',
        method: 'get',
        params: query
    })
}

//搜索页
export function getMessagerByCode(query) {
    return request({
        url: '/getMessagerByCode',
        method: 'get',
        params: query
    })
}

//根据ID取描述 获取商品详细信息
export function getMessagerById(query) {
    return request({
        url: '/getMessagerById',
        method: 'get',
        params: query
    })
}

//获取所有分公司（国家）
export function getCompanyList(query) {
    return request({
        url: '/getCompanyList',
        method: 'get',
        params: query
    })
}

//账户合计 经销商代办处公用
export function getPersonalData1(query) {
    return request({
        url: '/account/balanceSum',
        method: 'get',
        params: query
    })
}

//查询用户余额
export function getPersonalData2(query) {
    return request({
        url: '/account/getFiAcBalance',
        method: 'get',
        params: query
    })
}
//查询用户上次提现填写的信息
export function lastFiAcAppl(query) {
    return request({
        url: '/account/lastFiAcAppl',
        method: 'get',
        params: query
    })
}

//登录
export function Signindex(query) {
    return request({
        url: '/index',
        method: 'get',
        params: query
    })
}

//推举网络关系 经销商代办处公用
export function getPositionViews1(query) {
    return request({
        url: '/interView/miMemberRightViews',
        method: 'get',
        params: query
    })
}

// 推举网络关系-查询经销商树形图  经销商代办处公用
export function miMemberRightViewsTree(query) {
    return request({
        url: '/interView/miMemberRightViewsTree',
        method: 'get',
        params: query
    })
}

// 推举网络关系-查询经销商树形图数据  经销商代办处公用
export function miMemberRightViewsTreeData(query) {
    return request({
        url: '/interView/miMemberRightViewsTreeData',
        method: 'get',
        params: query
    })
}

//推荐网络查询 经销商代办处公用sysBanklist
export function getPositionViews2(query) {
    return request({
        url: '/interView/getMemberNetworkList',
        method: 'get',
        params: query
    })
}

//订购历史
export function orderList(query) {
    return request({
        url: 'order/member/orderList',
        method: 'get',
        params: query
    })
}

//订购历史 - 订单详情
export function orderView(query) {
    return request({
        url: 'order/member/orderView',
        method: 'get',
        params: query
    })
}

//订购历史 - 删除
export function memberdelete(query) {
    return request({
        url: 'order/member/delete',
        method: 'get',
        params: query
    })
}

//订购历史
export function saveOrUpdateFiAcInput(data) {
    return request({
        url: '/account/saveOrUpdateFiAcInput',
        method: 'post',
        data
    })
}

//获取当前用户的信用卡信息
export function creditCardShowI(query) {
    return request({
        url: '/miCreditCard/creditCardShowI',
        method: 'get',
        params: query
    })
}

//获取当前用户的银行信息
export function sysBanklist(query) {
    return request({
        url: '/sysBank/list',
        method: 'get',
        params: query
    })
}

//订购历史列表
export function getFiAcInputs(query) {
    return request({
        url: '/account/getFiAcInputs',
        method: 'get',
        params: query
    })
}

//提取资金
export function saveOrUpdateFiAcAppl(data) {
    return request({
        url: '/account/saveOrUpdateFiAcAppl',
        method: 'post',
        data
    })
}

//提取资金列表
export function getFiAcAppls(query) {
    return request({
        url: '/account/getFiAcAppls',
        method: 'get',
        params: query
    })
}

// 兑换历史保存 (执行)
export function saveOrUpdateFiAcEXChange(data) {
    return request({
        url: '/account/saveOrUpdateFiAcEXChange',
        method: 'POST',
        data
    })
}

// 货币兑换历史 查询
export function getFiAcEXChange(query) {
    return request({
        url: '/account/getFiAcEXChange',
        method: 'GET',
        params: query
    })
}

// 转移历史 查询
export function getFiAcTrans(query) {
    return request({
        url: '/account/getFiAcTrans',
        method: 'GET',
        params: query
    })
}

// 资金转账保存 (执行)
export function saveOrUpdateFiAcTrans(data) {
    return request({
        url: '/account/saveOrUpdateFiAcTrans',
        method: 'POST',
        data
    })
}


// 查询账户余额
export function getFiAcBalance(query) {
    return request({
        url: '/account/getFiAcBalance',
        method: 'GET',
        params: query
    })
}

// 查询账户信息
export function userMemberInfo(query) {
    return request({
        url: '/userInfo/miMemberInfo',
        method: 'GET',
        params: query
    })
}

// 保存订单
export function memberSaveOrder(data) {
    return request({
        url: '/order/member/saveOrder',
        method: 'POST',
        data
    })
}

// 保存订单2
export function memberSaveSGOrder(data) {
    return request({
        url: '/order/member/saveSGOrder',
        method: 'POST',
        data
    })
}

// 信用卡支付
export function onlinePayTon(data) {
    return request({
        url: '/order/member/onlinePayTon',
        method: 'POST',
        data
    })
}

// 升级/重消订单创建  代办处
export function agentSaveOrder(data) {
    return request({
        url: '/order/agent/saveOrder',
        method: 'POST',
        data
    })
}

// 查询余额和汇率
export function getExRateAndY(query) {
    return request({
        url: '/account/getExRateAndY',
        method: 'GET',
        params: query
    })
}

// 升级验证条件
export function memberUpOrderCheck(query) {
    return request({
        url: '/order/member/upOrderCheck',
        method: 'GET',
        params: query
    })
}

// 首购订单-商品列表  经销商/代办处
export function goodsListSGByMember(query) {
    return request({
        url: 'order/member/goodsListSGByMember',
        method: 'get',
        params: query
    })
}

// 升级单商品列表
export function goodsListSJByMember(query) {
    return request({
        url: 'order/member/goodsListSJByMember',
        method: 'get',
        params: query
    })
}

//重消单商品列表
export function goodsListCXByMember(query) {
    return request({
        url: 'order/member/goodsListCXByMember',
        method: 'get',
        params: query
    })
}

// 升级单验证条件
export function orderMemberNoCheck(query) {
    return request({
        url: '/order/member/memberNoCheck',
        method: 'GET',
        params: query
    })
}

// 继续支付
export function memberGoonOrder(data) {
    return request({
        url: '/order/member/goonOrder',
        method: 'POST',
        data
    })
}

// 在线支付
export function memberOnlinePay(data) {
    return request({
        url: '/order/member/onlinePay',
        method: 'POST',
        data
    })
}

// 在线支付结果
export function memberNotifyPay(data) {
    return request({
        url: '/order/member/notifyPay',
        method: 'POST',
        data
    })
}

//获取可提货的代办处
export function commonGetMiAgents(query) {
    return request({
        url: '/common/getMiAgents',
        method: 'get',
        params: query
    })
}

//订单收货  经销商
export function memberOrderRecs(data) {
    return request({
        url: '/order/member/orderRecs',
        method: 'post',
        data
    })
}

//商品无仓库提示  经销商
export function goodsStockAlert(data) {
    return request({
        url: '/order/member/goodsStockAlert',
        method: 'post',
        data
    })
}

//订单等级验证提示  经销商
export function orderPVAlert(query) {
    return request({
        url: '/order/member/orderPVAlert',
        method: 'get',
        params: query
    })
}

//验证首购单
export function memberSgOrderCheck(query) {
    return request({
        url: '/order/member/sgOrderCheck',
        method: 'get',
        params: query
    })
}

//新闻公告查询前台用
export function icAnnouncesList1(query) {
    return request({
        url: '/icAnnounces/list1',
        method: 'get',
        params: query
    })
}
//新闻公告查询前台用
export function icAnnouncesList2(query) {
    return request({
        url: '/icAnnounces/list2',
        method: 'get',
        params: query
    })
}

//新闻公告查询前台用(不分页)
export function getIcAnnouncesList(query) {
    return request({
        url: '/icAnnounces/getIcAnnouncesList',
        method: 'get',
        params: query
    })
}

//新闻公告查看放大镜和编辑查询接口
export function icAnnouncesView(query) {
    return request({
        url: '/icAnnounces/view',
        method: 'get',
        params: query
    })
}

// 新闻公告未读总数
export function unreadNum(query) {
    return request({
        url: '/icAnnounces/unreadNum',
        method: 'get',
        params: query
    })
}

//获取地区 联动
export function commonGetRecAddrs(query) {
    return request({
        url: '/common/getRecAddrs',
        method: 'get',
        params: query
    })
}

// 查询西欧收货地址
export function getRecAddrsWEU(query) {
    return request({
        url: '/common/getRecAddrsWEU',
        method: 'get',
        params: query
    })
}

//判断会员能报什么
export function memberNoCanOrder(query) {
    return request({
        url: '/order/member/memberNoCanOrder',
        method: 'get',
        params: query
    })
}

// 获取制度
export function getCompanyBonusType(query) {
    return request({
        url: 'common/getCompanyBonusType',
        method: 'get',
        params: query
    })
}

// 小区奖左右区结余业绩查询
export function bonusmentMainByUser() {
    return request({
        url: '/member/bonusmentMainByUser',
        method: 'get'
    })
}

// 推荐网络业绩查询  代办处/经销商
export function performanceByMember(query) {
    return request({
        url: '/member/performanceByMember',
        method: 'get',
        params: query
    })
}

// 累计总奖金查询
export function bdSumByTime(query) {
    return request({
        url: '/account/bdSumByTime',
        method: 'get',
        params: query
    })
}

// 查询达成荣弦所需业绩条件
export function getCardType(query) {
    return request({
        url: '/interView/getCardType',
        method: 'GET',
        params: query
    })
}

// 提现管理获取基本信息
export function loadFiAcAppl(query) {
    return request({
        url: 'account/loadFiAcAppl',
        method: 'GET',
        params: query
    })
}

// 表格查看信息
export function viewMemberTable(query) {
    return request({
        url: '/interView/viewMemberTable',
        method: 'get',
        params: query
    })
}

// 获取经销商邮箱后缀
export function getMemberEmailSuffix(query) {
    return request({
        url: '/member/getMemberEmailSuffix',
        method: 'get',
        params: query
    })
}

// 获取可参与的促销
export function getAttendCuxiao(data) {
    return request({
        url: 'order/member/getAttendCuxiao',
        method: 'post',
        data
    })
}

// 在线支付
export function ordermemberopenPay1(data) {
    return request({
        url: 'order/member/openPay1',
        method: 'post',
        data
    })
}

// 获取充值单号
export function openPayfiInput(data) {
    return request({
        url: 'order/member/openPayfiInput',
        method: 'post',
        data
    })
}
