import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/black-5-sale',
    component: InnerLayout,
    redirect: '/black-5-sale/index',
    meta: {
        title: 'black-5-sale' //黑5促销
    },
    children: [
        {
            path: 'index',
            component: () => import('@/views/black-5-sale/Black5Sale.vue'),
            name: 'Black5Sale',
            meta: {title: 'Black_friday.Promotion'},  //黑色星期五促销
        }
    ]
}]
