import InnerLayout from '@/views/inner-layout'

export default [{
    path: '/news-messages',
    component: InnerLayout,
    redirect: '/news-messages/index',
    meta: {
        title: 'app.news' // 新闻资讯
    },
    children: [
        {
            path: 'news',
            component: () => import('@/views/news-messages/News'),
            name: 'News',
            meta: {title: 'ic.news.announce'},  //新闻公告
        }
    ]
}]
